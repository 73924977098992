<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Nuevo Pais..." icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
						<!-- <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedPaises || !selectedPaises.length" /> -->
						<Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="download" />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="paises" v-model:selection="selectedPaises" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} paises">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Catálogo de Paises</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<Column field="clave_pais" header="Clave" :sortable="true"></Column>
					<Column field="pais" header="Pais" :sortable="true"></Column>
                    <Column field="activo" header="Activo" :sortable="true">
						<template #body="slotProps">
                                    {{turno(slotProps.data.activo)}}
                        </template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editPais(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeletePais(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="paisDialog" :style="{width: '450px'}" header="Pais" :modal="true" class="p-fluid">

                    <div class="p-field">
						<label for="clave">Clave *</label>
						<InputText id="clave" v-model.trim="pais.clave_pais" required="true" autofocus :class="{'p-invalid': submitted && !pais.clave_pais}" />
						<small class="p-invalid" v-if="submitted && !pais.clave_pais">La clave del pais es requerido</small>
					</div>

					<div class="p-field">
						<label for="pais">Pais *</label>
						<InputText id="pais" v-model.trim="pais.pais" required="true" autofocus :class="{'p-invalid': submitted && !pais.pais}" />
						<small class="p-invalid" v-if="submitted && !pais.pais">El nombre de la pais es requerido</small>
					</div>

                    <div class="p-field-checkbox">
                        <Checkbox id="activo" name="activo"  :binary="true" v-model="pais.activo" />
                        <label for="activo">Activo</label>
                    </div>

					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="savePais" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deletePaisDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="pais">¿Desea eliminar el pais <b>{{pais.nombre}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePaisDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deletePais" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deletePaisesDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="pais">¿Desea eliminar los paises seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePaisesDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedPaises" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import PaisService from '../service/PaisService';
import XLSX from 'xlsx'

export default {
	data() {
		return {
			paises: null,
			paisDialog: false,
			deletePaisDialog: false,
			deletePaisesDialog: false,
			pais: {},
			selectedPaises: null,
			filters: {},
			submitted: false,
			PaisExpor:null,
		}
	},
	paisService: null,
	created() {
		this.paisService = new PaisService();
	},
	mounted() {
		this.paisService.getPaises().then(data => this.paises = data);
	},
	methods: {
		openNew() {
			this.pais = {};
			this.submitted = false;
			this.paisDialog = true;
			this.pais.activo=true;
		},
		hideDialog() {
			this.paisDialog = false;
			this.submitted = false;
		},
		savePais() {
			this.submitted = true;
           
			if (this.pais.pais.trim()) {
				if (this.pais.id_pais >0) {

					this.paisService.updatePaises(this.pais).then(document.location.reload());			 
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Pais actualizado', life: 3000});
					
					
					
				}
				else {
				 
					this.paisService.createPaises(this.pais).then();					
					document.location.reload();
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Pais creado', life: 3000});
				}

				this.paisDialog = false;
				 
			}
		},
		editPais(pais) {
			this.pais = {...pais};
			this.paisDialog = true;
		},
		confirmDeletePais(pais) {
			this.pais = pais;
			this.deletePaisDialog = true;
		},
		deletePais() {
			
			this.deletePaisDialog = false;
			this.pais.activo=0;
			this.paisService.updatePaises(this.pais).then(document.location.reload());	 
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Pais eliminado', life: 3000});
			 
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.paises.length; i++) {
				if (this.paises[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
            let id = this.paises.length + 1;
            /*
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            */
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deletePaisesDialog = true;
		},
		deleteSelectedPaises() {
			this.paises = this.paises.filter(val => !this.selectedPaises.includes(val));
			this.deletePaisesDialog = false;
			this.selectedPaises = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Paises eliminados', life: 3000});
		},
		turno(value){
			if(value ==true){
				return 'Activo'
			}else{
				return 'Inactivo'
			}
		},
		download : function() {
			this.paisService.getPaisesExport().then(data => {
			this.PaisExpor = data
				if(this.PaisExpor != null){
				const data = XLSX.utils.json_to_sheet(this.PaisExpor)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'CatalogoPais.xlsx')
			}
			});
		},
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pais-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .pais-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.pais-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}
</style>
