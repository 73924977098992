import axios from 'axios';

export default class PaisService {

	getPaises() {
		//return axios.get('assets/layout/data/paises.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/paises`;
		const url = `/api/paises`;
		return axios.get(url).then(res=>res.data.data);
	}

	createPaises(paises) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/paises`;
		const url = `/api/paises`;
		return axios.post(url,paises).then(res=>res.data.data);
	}
	
	updatePaises(paises) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/paises/${paises.id_pais}`;
		const url = `/api/paises/${paises.id_pais}`;
		return axios.put(url,paises).then(res=>res.data.data);
	}
	
	deletePaises(paises) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/paises/${paises.id_pais}`;
		const url = `/api/paises/${paises.id_pais}`;
		return axios.delete(url).then(res=>res.data.data);
	}
	
	getPaisesExport() {
		//return axios.get('assets/layout/data/paises.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/paisesexport`;
		const url = `/api/paisesexport`;
		return axios.get(url).then(res=>res.data.data);
	}
}